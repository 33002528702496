<template>
  <div style="display:flex;align-items: center;flex-wrap: wrap">

    <div v-for="(item,i) in season_list" :key="item.id" style="margin: 15px">
      <a-card hoverable style="width: 250px;border-radius: 9px" :bodyStyle="{height:'90px'}"
              @click="editSeason(item.id,i)" @click.right="delIt(item.id)">
        <img
            slot="cover"
            :alt="item.img_id"
            :title="item.season_name"
            height="120px"
            width="100%"
            style="border-top-right-radius: 9px;border-top-left-radius: 9px"
            :src="`/txz_image/${item.img_id}.png`"
        />

        <a-card-meta :title="item.season_name" :description="item.item_name">

        </a-card-meta>
      </a-card>


      <a-modal v-model="modal_status.show[i]" title="通行证编辑" @ok="editSeasonModalOk(item.id,i)" okText="修改"
               cancelText="取消修改"
               @cancel="editSeasonModalCancel(i)">
        <div style="margin: 16px 0 ">
          <a-input addon-before="中文名:" v-model="modal_status.editObj.season_name"/>
        </div>
        <div style="margin-bottom: 16px">
          <a-input addon-before="英文名:" v-model="modal_status.editObj.item_name"/>
        </div>
        <div style="margin-bottom: 16px">
          <a-input addon-before="图片ID:" v-model="modal_status.editObj.img_id"/>
        </div>
      </a-modal>
    </div>

    <a-card hoverable style="width: 250px;border-radius: 19px;box-shadow: 1px 1px 15px 1px rgba(0,0,0,0.1)"
            @click="addSeasonStatus=true"
            :bodyStyle="{height:'90px'}">
      <div
          slot="cover"
          style="height: 210px;display: flex;justify-content: center;align-items: center;font-size: 20px;font-weight: 600">
        <div>新增通行证</div>
      </div>


    </a-card>


    <a-modal v-model="addSeasonStatus" title="添加通行证" @ok="addSeason()" okText="添加"
             cancelText="取消"
             @cancel="addSeasonStatus = false">
      <div style="margin: 16px 0 ">
        <a-input addon-before="中文名:" v-model="modal_status.editObj.season_name"/>
      </div>
      <div style="margin-bottom: 16px">
        <a-input addon-before="英文名:" v-model="modal_status.editObj.item_name"/>
      </div>
      <div style="margin-bottom: 16px">
        <a-input addon-before="图片ID:" v-model="modal_status.editObj.img_id"/>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'txz',
  data() {
    return {
      season_list: [],
      modal_status: {
        show: [],
        editObj: {}
      },
      CopyData: {},
      addSeasonStatus: false,
    }
  },
  methods: {
    editSeason(v, key) {
      this.modal_status.editObj = {}
      Object.assign(this.CopyData, this.season_list[key])
      this.modal_status.editObj = this.season_list.find(value => value.id == v)
      this.$set(this.modal_status.show, key, true)
    },
    editSeasonModalOk(v, key) {
      this.$axios.post(`/Admin/Api/SeasonTicket/EditSeasonTicket/${v}`, {
        ...this.modal_status.editObj
      }).then(value => {
        this.$message.success(value.data.msg)
        this.$set(this.modal_status.show, key, false)
      }).catch(err => {
        this.$message.error(err.msg)
        this.$set(this.modal_status.show, key, false)
      })
      this.modal_status.editObj = {}
    },
    editSeasonModalCancel(key) {
      this.$message.warning('取消修改', 1)
      Object.assign(this.season_list[key], this.CopyData)
      this.$set(this.modal_status.show, key, false)
      this.modal_status.editObj = {}
    },

    addSeason() {
      this.$axios.post('/Admin/Api/SeasonTicket/AddSeasonTicket', {
        ...this.modal_status.editObj
      }).then(value => {
        const id = JSON.parse(value.data.data).id
        this.modal_status.editObj.id = id
        this.season_list.push(this.modal_status.editObj)
        this.modal_status.editObj = {}
        this.$message.success(value.data.msg, 2)
      }).catch(err => this.$message.success(err.msg, 2)
      )
      this.addSeasonStatus = false
    },

    delIt(v) {
      const self = this
      this.$confirm({
        title: '是否删除该通行证',
        content: '此操作不可恢复，只能重新添加',
        okText: '确定删除',
        cancelText: '取消',
        onOk() {
          self.$axios.post(`/Admin/Api/SeasonTicket/DelSeasonTicket/${v}`).then(
              value => {
                self.$message.success(value.data.msg)
                self.season_list = self.season_list.filter(val => val.id != v)
              }
          ).catch(err => {
            self.$message.success(err.msg)
          })
        },
        onCancel() {
        },
      });
    },
  },
  mounted() {
    window.oncontextmenu = function (e) {
      e.preventDefault();
    };
  },
  async created() {
    this.season_list = JSON.parse((await this.$axios.post('/Admin/Api/SeasonTicket/GetSeasonTicket')).data.data)
  }
}
</script>

<style scoped>

</style>